import "react-phone-number-input/style.css";
import React, { useState, useEffect } from "react";
import Config from '../config/Config';
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate, useLocation } from "react-router-dom";

const SetMatch = ({ step, setCode, setStep }) => {
  const [first_score, setFirstScore] = useState("");
  const [red_cards, setRedCards] = useState("");
  const [yellow_cards, setYellowCards] = useState("");
  const [score_bd, setScoreBd] = useState("");
  const [score_rm, setScoreRm] = useState("");
  const [winner, setWinner] = useState("");


  const navigate = useNavigate();
  const location = useLocation();

  const loadMatch = async () => {
    const response = await axios.get(Config.API('match'));

    setFirstScore(response.data.first_score)
    setWinner(response.data.winner)
    setScoreBd(response.data.score_bd)
    setScoreRm(response.data.score_rm)
    setYellowCards(response.data.yellow_cards)
    setRedCards(response.data.red_cards)
  };

  const saveMatch = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const response = await axios.post(Config.API('match'), {
      first_score,
      winner,
      score_bd,
      score_rm,
      yellow_cards,
      red_cards
    })

    Swal.fire("Guardado", "Se ha guardado el match", "success");

    loadMatch()
  };

  useEffect(() => {
    loadMatch()
  }, []);

  return (
    <div className="bg-dark-blue text-white absolute top-5 text-center welcome-box right-4 left-4">
      <div className="flex justify-center items-center">
        <div className="text-center">
          <p className="text-2xl md:text-3xl text-center">Ingresa tus datos personales<br />y tu código</p>
          <form onSubmit={saveMatch}>
            <div className="mb-4">
              <label htmlFor="equipo" className="block text-sm font-medium text-white mt-4">
                Selecciona equipo ganador:
              </label>
              <select
                id="equipo"
                name="equipo"
                className="bg-transparent border-2 border-gray-200 rounded-full py-1 md:py-2 px-2 md:px-4 text-white text-center text-xl md:text-2xl leading-tight focus:outline-none focus:border-white-500"
                value={winner}
                onChange={(e) => {
                  setWinner(e.target.value);
                }}
              >
                <option value="" className="text-black" disabled>
                  Equipo ganador
                </option>
                <option value="Borussia Dortmund" className="text-black">
                  Borussia Dortmund
                </option>
                <option value="Real Madrid" className="text-black">
                  Real Madrid
                </option>
              </select>
            </div>

            <div className="mb-4">
              <label htmlFor="equipo" className="block text-sm font-medium text-white mt-4">
                Primer gol de:
              </label>
              <select
                id="first_score"
                name="first_score"
                className="bg-transparent border-2 border-gray-200 rounded-full py-1 md:py-2 px-2 md:px-4 text-white text-center text-xl md:text-2xl leading-tight focus:outline-none focus:border-white-500"
                value={first_score}
                onChange={(e) => {
                  setFirstScore(e.target.value);
                }}
              >
                <option value="" className="text-black" disabled>
                  Primer gol
                </option>
                <option value="Borussia Dortmund" className="text-black">
                  Borussia Dortmund
                </option>
                <option value="Real Madrid" className="text-black">
                  Real Madrid
                </option>
              </select>
            </div>

            <div className="mb-4">
              <label htmlFor="equipo" className="block text-sm font-medium text-white mt-4">
                ¿Cuál es el marcador?
              </label>
            </div>

            <div className="flex mb-4 space-x-4">
              <div className="mb-4 flex-1">
                <label htmlFor="golBorrusia" className="block text-sm font-medium text-white mt-4">
                  Borrusia Dortmund
                </label>
                <input
                  type="number"
                  id="golBorrusia"
                  value={score_bd}
                  onChange={(e) => {
                    setScoreBd(e.target.value.replace(/\D/g, ""));
                  }}
                  placeholder="Borussia Dortmund"
                  className={`bg-transparent border-2 border-red-800 rounded py-2 px-4 text-white leading...`}
                />
              </div>

              <div className="mb-4 flex-1">
                <label htmlFor="golMadrid" className="block text-sm font-medium text-white mt-4">
                  Real Madrid
                </label>
                <input
                  type="number"
                  id="golMadrid"
                  value={score_rm}
                  onChange={(e) => {
                    setScoreRm(e.target.value.replace(/\D/g, ""));
                  }}
                  placeholder="Real Madrid"
                  className={`bg-transparent border-2 border-red-800 rounded py-2 px-4 text-white leading...`}
                />
              </div>
            </div>


            <div className="mb-4">
              <label htmlFor="equipo" className="block text-sm font-medium text-white mt-4">
                ¿Cuantás tarjetas se dieron?
              </label>
            </div>

            <div className="flex mb-4 space-x-4">
              <div className="mb-4 flex-1">
                <label className="block text-white">Amarillas</label>
                <input
                  type="number"
                  id="yellowCards"
                  value={yellow_cards}
                  onChange={(e) => {
                    setYellowCards(e.target.value.replace(/\D/g, ""));
                  }}
                  placeholder="Amarillas"
                  className={`bg-transparent border-2 border-red-800 rounded py-2 px-4 text-white leading...`}
                />
              </div>

              <div className="mb-4 flex-1">
                <label className="block text-white">Rojas</label>
                <input
                  type="number"
                  id="redCards"
                  value={red_cards}
                  onChange={(e) => {
                    setRedCards(e.target.value.replace(/\D/g, ""));
                  }}
                  placeholder="Rojas"
                  className={`bg-transparent border-2 border-red-800 rounded py-2 px-4 text-white leading...`}
                />
              </div>
            </div>
            <div className="flex justify-center">
              <div className="flex justify-center">
                <button
                  className="btn-confirmar"
                  type="submit"
                >
                  Guardar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SetMatch;
