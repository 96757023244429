import "react-phone-number-input/style.css";
import React, { useState, useEffect } from "react";
import config from '../config/Config';
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import QRCode from 'qrcode';
import mergeImages from 'merge-images';

import baseImage from '../Images/invitation.jpg'
import { storage } from "../firebase/firebase";

const DataUserForm = ({ step, setCode, setStep }) => {
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [email, setEmail] = useState("");
  const [codigo, setCodigo] = useState("");
  const [golBorrusia, setGolBorrusia] = useState("");
  const [firstGolBorrusia, setFirstGolBorrusia] = useState(false);
  const [golMadrid, setGolMadrid] = useState("");
  const [firstGolMadrid, setFirstGolMadrid] = useState(false);
  const [yellowCards, setYellowCards] = useState('');
  const [redCards, setRedCards] = useState('');
  const [dpi, setDpi] = useState("");
  const [telefono, setTelefono] = useState("");
  const [error, setError] = useState("");
  const [imageProfile, setImageProfile] = useState("");
  const [enableButton, setEnableButton] = useState(true);
  const [qrCodeData, setQRCodeData] = useState('');
  const [selectedEquipo, setSelectedEquipo] = useState("");
  const [isFileUploaded, setIsFileUploaded] = useState(false); // Nuevo estado para reflejar si se ha subido un archivo

  const [nombreError, setNombreError] = useState(false);
  const [apellidoError, setApellidoError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [codigoError, setCodigoError] = useState(false);
  const [golBorrusiaError, setGolBorrusiaError] = useState(false);
  const [firstGolBorrusiaError, setFirstGolBorrusiaError] = useState(false);
  const [golMadridError, setGolMadridError] = useState(false);
  const [firstGolMadridError, setFirstGolMadridError] = useState(false);
  const [yellowCardsError, setYellowCardsError] = useState(false);
  const [redCardsError, setRedCardsError] = useState(false);
  const [dpiError, setDpiError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [codeError, setCodeError] = useState(false);

  const font = new FontFace('heineken-regular', 'url(./fonts/HeinekenSans-Regular.woff)');

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => { }, [nombreError, apellidoError, emailError, phoneError, codeError]);
  const handleFileUpload = (e) => {
    var imageFile = e.target.files[0];

    if (imageFile && !["image/jpeg", "image/png", "image/webp"].includes(imageFile.type)) {
      Swal.fire(
        "Imagen no Compatible",
        "Formato de imagen no compatible por favor utiliza: png, jpg, jpeg ó webp",
        "error"
      );
      // 👇️ reset file input
      setIsFileUploaded(false);
      return;
    }

    if (imageFile && imageFile.size > 5242880) {
      Swal.fire("Imagen muy grande", "Imagen excede los 5MB permitidos.", "error");
      // 👇️ reset file input
      setIsFileUploaded(false);
      return;
    }

    setImageProfile(e.target.files[0]);
    setIsFileUploaded(true); // Establecer el estado como true cuando se suba un archivo
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const fields = {
      nombre: setNombreError,
      apellido: setApellidoError,
      email: setEmailError,
      codigo: setCodigoError,
      dpi: setDpiError,
      telefono: setPhoneError,
      golBorrusia: setGolBorrusiaError,
      firstGolBorrusia: setFirstGolBorrusiaError,
      golMadrid: setGolMadridError,
      firstGolMadrid: setFirstGolMadridError,
      yellowCards: setYellowCardsError,
      redCards: setRedCardsError,
    };

    Object.entries(fields).forEach(([field, setError]) => {
      if (field === "") {
        setError(true);
      }
    });

    const fieldsRequired = [
      'nombre',
      'apellido',
      'email',
      'telefono',
      'codigo',
      'dpi',
      'golBorrusia',
      'firstGolBorrusia',
      'golMadrid',
      'firstGolMadrid',
      'yellowCards',
      'redCards',
    ];

    const emptyField = fieldsRequired.find(field => !field);

    if (emptyField) {
      Swal.fire("Campo Vacio", "Debe ingresar todos los campos", "error");
      return false;
    }

    if (dpi.length < 13) {
      setEnableButton(true);
      Swal.fire("DPI no valido", "El número de DPI ingresado no es valido.", "error");
      return false;
    }

    if (imageProfile && !["image/jpeg", "image/png", "image/webp"].includes(imageProfile.type)) {
      Swal.fire("Imagen no soportada", "El archivo que intenta subir no es compatible", "error");
      return false;
    }

    if (imageProfile && imageProfile.size > 5242880) {
      Swal.fire("Muy grande", "El archivo que intenta subir supera los 5MB", "error");
      return false;
    }

    setError("");

    Swal.fire({
      title: "Procesando datos...",
      html: "",
      timer: 35000,
      timerProgressBar: true,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });

    try {
      let uniqueId = `_${new Date().getTime()}`;

      const selfie_url = await handleSelfieUpload(uniqueId)
      console.log("🚀 ~ handleSubmit ~ selfie_url:", selfie_url)

      const invitation_qr = await generateQRCode();
      console.log("🚀 ~ handleSubmit ~ invitation_qr:", invitation_qr)

      const response = await axios.post(config.API('customer'), {
        nombre,
        apellido,
        email,
        codigo,
        celular: telefono,
        selfie_url: selfie_url,
        invitation_url: invitation_qr,
        cromo_url: "",
        golBorrusia,
        firstGolBorrusia,
        golMadrid,
        firstGolMadrid,
        yellowCards,
        redCards,
        dpi,
        selectedEquipo,
        uniqueId,
      });

      console.log("🚀 ~ response ~ response:", response)

      if (response.data.type) {
        Swal.fire("Proceso Completado", "Se envio por WhatsApp", "success");
        navigate('/validate', { state: { name: `${nombre} ${apellido}`, code: codigo } })
      } else {
        Swal.fire("Proceso fallido", response.data.message, "error");
        navigate('/invalidate', { state: { name: `${nombre} ${apellido}`, code: codigo } })
        return false;
      }
    } catch (success) {
      setEnableButton(true);
    }
  };

  const handleSelfieUpload = async (uniqueId) => {
    const imageRef = storage.ref("selfies_2024").child(`${dpi}_${uniqueId}`);
    const uploadTask = imageRef.put(imageProfile, { contentType: "image/png", public: true });

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        null,
        error => {
          setEnableButton(true);
          reject(error);
        },
        async () => {
          try {
            const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
            resolve(downloadURL);
          } catch (error) {
            setEnableButton(true);
            reject(error);
          }
        }
      );
    });
  };


  const generateQRCode = async () => {
    const textToEncode = `https://www.finalheineken.com/confirmation/${codigo}`

    try {
      const qrCodeDataUrl = await QRCode.toDataURL(textToEncode);
      setQRCodeData(qrCodeDataUrl);
      return await superponerImagenes(qrCodeDataUrl);
    } catch (error) {
      console.error('Error al generar el código QR:', error);
    }
  };


  const superponerImagenes = async (qrData) => {
    const QRBase64 = qrData;
    const invitationAsset = baseImage;

    const imagenOriginal = new Image();
    imagenOriginal.src = QRBase64;

    return new Promise((resolve, reject) => {
      imagenOriginal.onload = async () => {
        const canvas = document.createElement('canvas');
        canvas.width = 611;
        canvas.height = 611;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(imagenOriginal, 0, 0, canvas.width, canvas.height);

        const nuevaImagenBase64 = canvas.toDataURL();

        try {
          const nuevaImagen = await mergeImages([
            { src: invitationAsset, x: 0, y: 0 },
            { src: nuevaImagenBase64, x: 304, y: 1665 },
          ]);

          const invitation = new Image();
          invitation.src = nuevaImagen;

          invitation.onload = async () => {
            const icanvas = document.createElement('canvas');
            icanvas.width = invitation.width;
            icanvas.height = invitation.height;

            let ictx = icanvas.getContext('2d');

            ictx.drawImage(invitation, 0, 0, icanvas.width, icanvas.height);


            ictx.font = '3.5em heineken-regular';
            ictx.fillStyle = 'white';

            // Measure the width of the text
            let textWidth = ictx.measureText(`${nombre} ${apellido}`).width;

            // Calculate the position to start drawing the text
            let positionX = (icanvas.width - textWidth) / 2;

            // Draw the text
            ictx.fillText(`${nombre} ${apellido}`, positionX, 650);

            // Repeat for the second line of text
            textWidth = ictx.measureText(`DPI: ${dpi}`).width;
            positionX = (icanvas.width - textWidth) / 2;
            ictx.fillText(`DPI: ${dpi}`, positionX, 750);

            let invitationB64 = icanvas.toDataURL();
            resolve(await handleImageUpload(invitationB64));
          }
        } catch (error) {
          console.log(error);
          reject(error);
        }
      };
    });
  };


  const handleEquipoChange = (event) => {
    setSelectedEquipo(event.target.value);
  };

  const handleImageUpload = async (base64String, cromo = false) => {
    const imageRef = storage.ref('invitation_qr').child(`${dpi}${cromo ? '_cromo' : ''}`);
    const uploadTask = imageRef.putString(base64String.split(',')[1], "base64", { contentType: 'image/png' });

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        null,
        error => reject(error),
        async () => {
          try {
            const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
            resolve(downloadURL);
          } catch (error) {
            reject(error);
          }
        }
      );
    });
  };

  return (
    <div className="bg-dark-blue absolute right-10 md:right-40 p-8 top-5 md:top-10 w-96 md:w-2/5 mx-auto md:text-center center-mobile welcome-box">
      <div className="flex justify-center items-center">
        <div className="text-center">
          <p className="text-2xl md:text-3xl text-center text-white">Ingresa tus datos personales<br />y tu código</p>
          {error && <p className="error-message mb-4">{error}</p>}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <input
                type="text"
                id="nombre"
                value={nombre}
                onChange={(e) => {
                  setNombre(e.target.value.replace(/\s/g, ""));
                  setNombreError(e.target.value === "");
                }}
                placeholder="Nombre"
                className={`bg-transparent border-2 border-red-800 rounded py-2 px-4 text-red leading... ${nombreError ? "input2" : ""
                  }`}
              />
            </div>
            <div className="mb-4">
              <input
                type="text"
                id="apellido"
                value={apellido}
                onChange={(e) => {
                  setApellido(e.target.value.replace(/\s/g, ""));
                  setApellidoError(e.target.value === "");
                }}
                placeholder="Apellido"
                className={`bg-transparent border-2 border-red-800 rounded py-2 px-4 text-white leading... ${apellidoError ? "input2" : ""
                  }`}
              />
            </div>

            <div className="mb-4">
              <input
                type="text"
                id="dpi"
                value={dpi}
                onChange={(e) => {
                  setDpi(e.target.value.replace(/\s/g, ""));
                  setDpiError(e.target.value === "");
                }}
                placeholder="DPI"
                className={`bg-transparent border-2 border-red-800 rounded py-2 px-4 text-white leading... ${apellidoError ? "input2" : ""
                  }`}
              />
            </div>

            <div className="mb-4">
              <input
                type="email"
                id="email"
                value={email}
                placeholder="Email"
                onChange={(e) => {
                  setEmail(e.target.value.replace(/\s/g, ""));
                  setEmailError(e.target.value === "");
                }}
                className={`bg-transparent border-2 border-red-800 rounded py-2 px-4 text-white leading... ${emailError ? "input2" : ""
                  }`}
              />
            </div>

            <div className="mb-4">
              <input
                type="text"
                id="codigo"
                value={codigo}
                onChange={(e) => {
                  setCodigo(e.target.value.replace(/\s/g, ""));
                  setCodigoError(e.target.value === "");
                }}
                placeholder="Codigo"
                className={`bg-transparent border-2 border-red-800 rounded py-2 px-4 text-white leading... ${codigoError ? "input2" : ""
                  }`}
              />
            </div>

            <PhoneInput
              style={{ color: "black" }}
              flags={flags}
              defaultCountry="GT"
              placeholder="Celular"
              value={telefono}
              onChange={(e) => {
                console.log(e);
                setTelefono(e);
                setPhoneError(e === "");
              }}
            />
            <div className="mb-4">
              <label htmlFor="equipo" className="block text-sm font-medium text-white mt-4">
                Selecciona tu equipo favorito:
              </label>
              <select
                id="equipo"
                name="equipo"
                className="bg-transparent border-2 border-gray-200 rounded-full py-1 md:py-2 px-2 md:px-4 text-white text-center text-xl md:text-2xl leading-tight focus:outline-none focus:border-white-500"
                value={selectedEquipo}
                onChange={handleEquipoChange}
              >
                <option value="" className="text-black" disabled>
                  Seleciona un equipo
                </option>
                <option value="Borussia Dortmund" className="text-black">
                  Borussia Dortmund
                </option>
                <option value="Real Madrid" className="text-black">
                  Real Madrid
                </option>
              </select>
            </div>

            <div className="mb-4">
              <label htmlFor="equipo" className="block text-sm font-medium text-white mt-4">
                ¿Cuál crees que sera el marcador?
              </label>
            </div>

            <div className="flex mb-4 space-x-4">
              <div className="mb-4 flex-1">
                <input
                  type="number"
                  id="golBorrusia"
                  value={golBorrusia}
                  onChange={(e) => {
                    setGolBorrusia(e.target.value.replace(/\D/g, ""));
                    setGolBorrusiaError(e.target.value === "");
                  }}
                  placeholder="Borussia Dortmund"
                  className={`bg-transparent border-2 border-red-800 rounded py-2 px-4 text-white leading... ${golBorrusiaError ? "input2" : ""
                    }`}
                />
              </div>

              <div className="mb-4 flex-1">
                <input
                  type="number"
                  id="golMadrid"
                  value={golMadrid}
                  onChange={(e) => {
                    setGolMadrid(e.target.value.replace(/\D/g, ""));
                    setGolMadridError(e.target.value === "");
                  }}
                  placeholder="Real Madrid"
                  className={`bg-transparent border-2 border-red-800 rounded py-2 px-4 text-white leading... ${golMadridError ? "input2" : ""
                    }`}
                />
              </div>
            </div>


            <div className="mb-4">
              <label htmlFor="equipo" className="block text-sm font-medium text-white mt-4">
                ¿Cuál equipo meterá gol primero?
              </label>
            </div>

            <div className="flex mb-4 space-x-4">
              <label htmlFor="firstGolBorrusia" className="block text-white">
                <input
                  type="checkbox"
                  id="firstGolBorrusia"
                  value={firstGolBorrusia}
                  onChange={(e) => {
                    setFirstGolBorrusia(e.target.checked);
                    setFirstGolBorrusiaError(!e.target.checked);
                  }}
                  className={`bg-transparent border-2 border-red-800 rounded py-2 px-4 text-white leading... ${firstGolBorrusiaError ? "input2" : ""
                    }`}
                />
                Borussia Dortmund
              </label>

              <label htmlFor="firstGolMadrid" className="block text-white">
                <input
                  type="checkbox"
                  id="firstGolMadrid"
                  value={firstGolMadrid}
                  onChange={(e) => {
                    setFirstGolMadrid(e.target.checked);
                    setFirstGolMadridError(!e.target.checked);
                  }}
                  className={`bg-transparent border-2 border-red-800 rounded py-2 px-4 text-white leading... ${firstGolMadridError ? "input2" : ""
                    }`}
                />
                Real Madrid
              </label>
            </div>

            <div className="mb-4">
              <label htmlFor="equipo" className="block text-sm font-medium text-white mt-4">
                ¿Cuántas tarjetas se mostrarán durante el partido?
              </label>
            </div>

            <div className="flex mb-4 space-x-4">
              <div className="mb-4 flex-1">
                <label className="block text-white">Amarillas</label>
                <input
                  type="number"
                  id="yellowCards"
                  value={yellowCards}
                  onChange={(e) => {
                    setYellowCards(e.target.value.replace(/\D/g, ""));
                    setYellowCardsError(e.target.value === "");
                  }}
                  placeholder="Amarillas"
                  className={`bg-transparent border-2 border-red-800 rounded py-2 px-4 text-white leading... ${yellowCardsError ? "input2" : ""
                    }`}
                />
              </div>

              <div className="mb-4 flex-1">
                <label className="block text-white">Rojas</label>
                <input
                  type="number"
                  id="redCards"
                  value={redCards}
                  onChange={(e) => {
                    setRedCards(e.target.value.replace(/\D/g, ""));
                    setRedCardsError(e.target.value === "");
                  }}
                  placeholder="Rojas"
                  className={`bg-transparent border-2 border-red-800 rounded py-2 px-4 text-white leading... ${redCardsError ? "input2" : ""
                    }`}
                />
              </div>
            </div>



            <br />
            <div className="mb-4">
              <label htmlFor="foto" className="block text-1xl text-white">
                Sube tú foto para crear tú usuario
              </label>
              <input
                type="file"
                id="foto"
                name="foto"
                accept="image/*"
                onChange={handleFileUpload} // Actualización para llamar a la función handleFileUpload
                className="hidden"
              />
              <label
                htmlFor="foto"
                className={`flex items-center px-4 py-2 bg-blue-900 text-white rounded-md cursor-pointer ${isFileUploaded ? "bg-cyan-400" : ""
                  }`}
              >
                {isFileUploaded ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                  </svg>
                )}
                {isFileUploaded ? "Archivo seleccionado" : "Seleccionar archivo"}
              </label>
            </div>
            <div className="flex justify-center">
              <div className="flex justify-center">
                {enableButton && (
                  <button
                    className="btn-confirmar"
                    type="submit"
                  >
                    Confirmar
                  </button>
                )}
              </div>
            </div>
          </form>

        </div>
      </div>
      <a className='code-copy-no-absolute text-white' href='https://code.com.gt' target='_blank'>powered by CODE</a>
    </div>
  );
};

export default DataUserForm;
