import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import Swal from "sweetalert2";
import axios from 'axios'
import mergeImages from 'merge-images';
import { storage } from "../firebase/firebase";

import userCardImage from '../Images/user-card.png'
import userCardImageDefault from '../Images/default.png'

import config from '../config/Config';

const ConfimationPage = () => {
  const [enableButton, setEnableButton] = useState(false);
  const [name, setName] = useState("");
  const [code, setCode] = useState(useParams().code);
  const [dpi, setDpi] = useState("");
  const [userData, setUserData] = useState("")
  const [cromoUrl, setCromoUrl] = useState("")
  let message = ""
  const navigate = useNavigate()

  const loadCode = async () => {
    try {
      const response = await axios.get(config.API(`code/${code}`));
      if (response.status === 200 && !response.data.message) {
        setName(`${response.data.nombre} ${response.data.apellido}`);
        setDpi(response.data.dpi)
        setUserData(response.data)
        setEnableButton(true)
        return true
      } else {
        Swal.fire("No se puede confirmar", response.data.message, "error");
        navigate('/')
        return false
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return false
    }
  }

  const handleImageUpload = async (base64String, cromo = false) => {
    const imageRef = storage.ref('invitation_qr').child(`${userData.dpi}${cromo ? '_cromo' : ''}`);
    const uploadTask = imageRef.putString(base64String.split(',')[1], "base64", { contentType: 'image/png' });

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        null,
        error => reject(error),
        async () => {
          try {
            const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
            resolve(downloadURL);
          } catch (error) {
            reject(error);
          }
        }
      );
    });
  };

  const resizeImageFromUrl = async (imageUrl, targetWidth, targetHeight) => {
    try {
      // Fetch the image from the URL and convert it to a Blob
      const imageBlob = await fetch(imageUrl).then(response => response.blob());

      // Create an Image object
      const img = new Image();

      // Wait for the image to load completely
      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
        img.src = URL.createObjectURL(imageBlob);
      });

      // Calculate the new dimensions while maintaining the aspect ratio
      const aspectRatio = img.width / img.height;
      let newWidth, newHeight;

      if (aspectRatio > targetWidth / targetHeight) {
        newWidth = targetWidth;
        newHeight = Math.floor(targetWidth / aspectRatio);
      } else {
        newHeight = targetHeight;
        newWidth = Math.floor(targetHeight * aspectRatio);
      }

      // Create a canvas
      const canvas = document.createElement('canvas');
      canvas.width = targetWidth;
      canvas.height = targetHeight;

      const ctx = canvas.getContext('2d');

      // Draw the resized image on the canvas
      ctx.drawImage(img, (targetWidth - newWidth) / 2, (targetHeight - newHeight) / 2, newWidth, newHeight);

      // Get the resulting image in base64 (JPEG)
      const resizedBase64 = canvas.toDataURL('image/jpeg', 0.8); // Quality: 0.8

      return resizedBase64;
    } catch (error) {
      console.error('Error resizing the image:', error);
      // Convert the default image to base64
      const defaultImageBase64 = await fetch(userCardImageDefault)
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        }));
      return defaultImageBase64;
    }
  };

  const generateProfileImage = async () => {
    const uploadedImage = new Image();
    uploadedImage.src = await resizeImageFromUrl(userData.selfie_url, 1080, 1920)

    return new Promise((resolve, reject) => {
      uploadedImage.onload = async () => {
        const canvas = document.createElement('canvas');
        canvas.width = uploadedImage.width;
        canvas.height = uploadedImage.height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(uploadedImage, 0, 0, canvas.width, canvas.height);

        const nuevaImagenBase64 = canvas.toDataURL();

        try {
          const nuevaImagen = await mergeImages([
            { src: nuevaImagenBase64, x: 0, y: 0 },
            { src: userCardImage, x: 0, y: 0 },
          ]);

          const cardImage = new Image();
          cardImage.src = nuevaImagen;

          cardImage.onload = async () => {
            const icanvas = document.createElement('canvas');
            icanvas.width = cardImage.width;
            icanvas.height = cardImage.height;

            let ictx = icanvas.getContext('2d');

            ictx.drawImage(cardImage, 0, 0, icanvas.width, icanvas.height);


            ictx.font = '3.5em Arial';
            ictx.fillStyle = 'white';

            // Measure the width of the text
            let textWidth = 0;

            // Calculate the position to start drawing the text
            let positionX = 0;


            // Nombre
            textWidth = ictx.measureText(`${userData.nombre} ${userData.apellido}`).width;
            positionX = (icanvas.width - textWidth) / 2;
            ictx.fillText(`${userData.nombre} ${userData.apellido}`, positionX, 1175);

            // Marcador
            ictx.fillText(`MARCADOR`, 131, 1276);
            ictx.fillText(`RM ${userData.golMadrid} - BD ${userData.golBorrusia}`, 131, 1335);

            // 1er anotador
            ictx.fillText(`1ER ANOTADOR`, 433, 1276);
            ictx.fillText(userData.firstGolBorrusia ? `Borussia Dortmund` : `Real Madrid`, 433, 1335);

            // Tarjetas
            ictx.fillText(`TARJETAS`, 753, 1276);
            ictx.fillText(`A${userData.yellowCards} - R${userData.redCards}`, 753, 1335);

            let cardImageBase64 = icanvas.toDataURL();

            resolve(await handleImageUpload(cardImageBase64, true));
          }
        } catch (error) {
          console.log(error);
          reject(error);
        }
      };
    });
  }

  const onLoaded = async () => {
    const cromo_url = await generateProfileImage()
    console.log("🚀 ~ onLoaded ~ cromo_url:", cromo_url)
    setCromoUrl(cromo_url)
    setEnableButton(true)
  }

  useEffect(() => {
    if (userData) {
      onLoaded()
    }
  }, [userData]);

  useEffect(() => {
    loadCode()
  }, [])

  const handleConfirmation = async () => {
    Swal.fire({
      title: "Procesando datos...",
      html: "",
      timer: 3000,
      timerProgressBar: true,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });

    console.log("🚀 ~ response ~ cromoUrl:", cromoUrl)

    const response = await axios.post(config.API(`code/${code}/confirm`), {
      cromo_url: cromoUrl
    });


    console.log("🚀 ~ handleConfirmation ~ response:", response)

    if (response.status === 200) {
      Swal.fire("Proceso Completado", "Se ha confirmado al invitado", "success");
      navigate('/')
    }
  }

  return (
    <div
      style={{
        backgroundColor: "rgba(25, 122, 27, 0.8)",
        borderRadius: "10px",
        border: "1px solid white"
      }}
      className="bg-slate-950 bg-opacity-70 absolute right-10 md:right-40 p-8 top-10 md:top-10 w-60 md:w-2/5 mx-auto md:text-center center-mobile"
    >
      <div className="flex justify-center items-center">
        <div className="p-8 rounded shadow-md">
          <div
            style={{
              backgroundColor: "#020033",
              borderRadius: "10px",
              border: "1px solid white",
              padding: "8px",
              width: "95%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <label className="text-2xl mb-4 text-center text-white" style={{ margin: "auto" }}>{code}</label>
          </div>
          <br />
          <h1 className="text-xl mb-4 text-center text-white" style={{ fontFamily: "heineken-regular" }}>{name}</h1>
          <p className="text-xl text-white text-center" style={{ fontFamily: "heineken-regular" }}>DPI: {dpi}</p>
        </div>
      </div>
      {enableButton ? (
        <button
          className="rounded-full bg-blue-900 text-white py-2 px-4 col-span-3 mt-4 mr-auto ml-auto block"
          style={{ fontFamily: "heineken-regular" }}
          onClick={handleConfirmation}
        >
          Confirmar
        </button>
      ) : (
        <button
          className="rounded-full bg-gray-900 text-white py-2 px-4 col-span-3 mt-4 mr-auto ml-auto block"
          style={{ fontFamily: "heineken-regular" }}
        >
          Activo
        </button>
      )}
      {cromoUrl ? (<a href={cromoUrl} target='_blank'
        className="rounded-full bg-gray-900 text-white py-2 px-4 col-span-3 mt-4 mr-auto ml-auto block"
        style={{ fontFamily: "heineken-regular" }}
      >
        Ver mi estampilla
      </a>) : (<i></i>)
      }

    </div>
  );
};

export default ConfimationPage;
