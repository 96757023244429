import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const CongratulationsScreen = () => {
  const location = useLocation();
  const { name, code } = location.state;
  const navigate = useNavigate()

  return (
    <div
      style={{
        backgroundColor: "rgba(25, 122, 27, 0.8)",
        borderRadius: "10px",
        border: "4px solid white"
      }}
      className="bg-slate-950 bg-opacity-70 absolute right-10 md:right-40 p-8 top-10 md:top-10 w-80 md:w-2/5 mx-auto md:text-center center-mobile"
    >
      <div className="flex justify-center items-center">
        <div className="p-8 rounded shadow-md">
          <div
            style={{
              backgroundColor: "#020033",
              borderRadius: "10px",
              border: "1px solid white",
              padding: "8px",
              width: "95%", // Reducido en un 20%
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <label className="text-2xl  mb-4 text-center text-white" style={{ margin: "auto" }}>{code}</label>
          </div>
          <br />
          <h1 className="text-4xl mb-4 text-center text-white" style={{ fontFamily: "heineken-regular" }}>
            ¡Tu código ha sido verificado!
          </h1>
          <p className="text-4xl text-white text-center" style={{ fontFamily: "heineken-bold" }}>
            ¡Te esperamos para en la final!
          </p>

        </div>
      </div>
      <div className="flex justify-center mt-4">
        <button onClick={() => { navigate('/dataUser') }} className="border-1 border-white bg-blue-950 text-white py-2 px-8 col-span-2 text-lga" style={{ fontFamily: "heineken" }}>Inicio</button>
      </div>
    </div>

  );
};

export default CongratulationsScreen;