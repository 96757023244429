import React, { useEffect, useRef } from 'react';

const ConfetiAnimation = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const NUM_CONFETTI = 40;
    const COLORS = [
      [255, 255, 255],
      [255, 144, 0],
      [255, 255, 255],
      [255, 144, 0],
      [0, 277, 235]
    ];
    const PI_2 = 2 * Math.PI;

    let w = 0;
    let h = 0;
    let xpos = 0.9;
    let confetti = [];

    const resizeWindow = () => {
      w = canvas.width = window.innerWidth;
      h = canvas.height = window.innerHeight;
    };

    const range = (a, b) => (b - a) * Math.random() + a;

    const drawCircle = (x, y, r, style) => {
      context.beginPath();
      context.moveTo(x, y);
      context.bezierCurveTo(x - 17, y + 14, x + 13, y + 5, x - 5, y + 22);
      context.lineWidth = 3;
      context.strokeStyle = style;
      context.stroke();
    };

    const drawCircle2 = (x, y, r, style) => {
      context.beginPath();
      context.moveTo(x, y);
      context.lineTo(x + 10, y + 10);
      context.lineTo(x + 10, y);
      context.closePath();
      context.fillStyle = style;
      context.fill();
    };

    const drawCircle3 = (x, y, r, style) => {
      context.beginPath();
      context.moveTo(x, y);
      context.lineTo(x + 10, y + 10);
      context.lineTo(x + 10, y);
      context.closePath();
      context.fillStyle = style;
      context.fill();
    };

    const Confetti = function () {
      this.style = COLORS[Math.floor(range(2, 3))];
      this.rgb = `rgba(${this.style[0]},${this.style[1]},${this.style[2]}`;
      this.r = Math.floor(range(1, 0)); // Ajustar  velocidad de la animación 
      this.r2 = 2 * this.r;
      this.replace();
    };

    Confetti.prototype.replace = function () {
      this.opacity = 0.5;
      this.dop = 0.01 * range(1, 1); // Ajustar el tamaño de los confetis
      this.x = range(-this.r2, w - this.r2);
      this.y = range(-20, h - this.r2);
      this.xmax = w - this.r;
      this.ymax = h - this.r;
      this.vx = range(0, 0) + 8 * xpos - 5;
      this.vy = 0.7 * this.r + range(-1, 1);
    };

    Confetti.prototype.draw = function () {
      this.x += this.vx;
      this.y += this.vy;
      this.opacity += this.dop;

      if (this.opacity > 1) {
        this.opacity = 1;
        this.dop *= -1;
      }

      if (this.opacity < 0 || this.y > this.ymax) {
        this.replace();
      }

      if (!(0 < this.x && this.x < this.xmax)) {
        this.x = (this.x + this.xmax) % this.xmax;
      }

      drawCircle(Math.floor(this.x), Math.floor(this.y), this.r, `${this.rgb},${this.opacity})`);
      drawCircle3(Math.floor(this.x * 0.5), Math.floor(this.y), this.r, `${this.rgb},${this.opacity})`);
      drawCircle2(Math.floor(this.x * 1.5), Math.floor(this.y * 1.5), this.r, `${this.rgb},${this.opacity})`);
    };

    const step = () => {
      window.requestAnimationFrame(step);
      context.clearRect(0, 0, w, h);
      confetti.forEach((c) => c.draw());
    };

    const initializeConfetti = () => {
      confetti = [];
      for (let i = 0; i < NUM_CONFETTI; i++) {
        confetti.push(new Confetti());
      }
    };

    const handleMouseMove = (e) => {
      xpos = e.pageX / w;
    };

    window.addEventListener('resize', resizeWindow);
    window.addEventListener('mousemove', handleMouseMove);
    initializeConfetti();
    resizeWindow();
    step();

    return () => {
      window.removeEventListener('resize', resizeWindow);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return <canvas ref={canvasRef} id="confeti" />;
};

export default ConfetiAnimation;
