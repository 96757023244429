import React, { useState } from 'react';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';


const BirthDateForm = ({ step, setStep }) => {
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');

  const navigate = useNavigate();

  const isOver18 = () => {

    const today = new Date();
    const birthdate = new Date(year, month - 1, day); // month - 1 porque los meses en JavaScript comienzan en 0
    let age = today.getFullYear() - birthdate.getFullYear();
    const monthDiff = today.getMonth() - birthdate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthdate.getDate())) {
      age--; // Restar un año si la fecha actual es anterior al cumpleaños
    }

    return age >= 18;
  };

  const handleValidAge = () => {

    if (!day || !month || !year) {
      Swal.fire(
        'Campo Vacio',
        'Debe ingresar todos los campos',
        'error'
      )
      return;
    }

    if (isOver18()) {
      navigate('/dataUser');
    } else {
      Swal.fire(
        'Para ingresar a este sitio debes ser mayor de edad',
        '',
        'error'
      )
    }
  }

  const handleDayChange = (event) => {
    setDay(event.target.value);
  };

  const handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    setMonth(selectedMonth);

    // Validar si el mes seleccionado tiene 28, 30 o 31 días
    const daysInMonth = getDaysInMonth(selectedMonth, year);

    // Si el día seleccionado es mayor al número de días en el mes, reiniciar el día seleccionado
    if (day && day > daysInMonth) {
      setDay('');
    }
  };

  const handleYearChange = (event) => {
    const selectedYear = event.target.value;
    setYear(selectedYear);

    // Validar si el mes actual tiene 28, 30 o 31 días 
    const daysInMonth = getDaysInMonth(month, selectedYear);

    // Si el día seleccionado es mayor al número de días en el mes, reiniciar el día seleccionado
    if (day && day > daysInMonth) {
      setDay('');
    }
  };

  const getDaysInMonth = (month, year) => {
    const monthNumber = parseInt(month, 10);
    const yearNumber = parseInt(year, 10);

    if (monthNumber === 2) {
      // Comprobar si el año es bisiesto
      if ((yearNumber % 4 === 0 && yearNumber % 100 !== 0) || yearNumber % 400 === 0) {
        return 29; // Febrero tiene 29 días en años bisiestos
      } else {
        return 28; // Febrero tiene 28 días en años no bisiestos
      }
    } else if ([4, 6, 9, 11].includes(monthNumber)) {
      return 30; // Abril, junio, septiembre y noviembre tienen 30 días
    } else {
      return 31; // Los demás meses tienen 31 días
    }
  };

  return (
    <div className="absolute right-10 md:right-40 p-8 top-5 md:top-10 w-96 md:w-2/5 mx-auto md:text-center center-mobile">
      <div className="bg-dark-blue text-white text-center welcome-box">
        <p className="text-xl welcome-headline">Bienvenido a la FINAL</p>
        <p className="text-xl heineken-headline">Heineken®</p>
        <p className="text-xl heineken-headline year">2024</p>
        <div className="mt-4 text-center">
          <p className="md:text-2xl verification-text">Verifica tu edad antes de ingresar</p>
        </div>
        <form className="grid grid-cols-3 gap-4 mt-8">
          <div className="flex flex-col">
            <label htmlFor="day" className="text-lg md:text-xl selector-label">Día:</label>
            <select
              id="day"
              value={day}
              onChange={handleDayChange}
              className="bg-transparent border-2 border-gray-200 rounded-full py-1 md:py-2 px-2 md:px-4 text-white text-center text-xl md:text-2xl leading-tight focus:outline-none focus:border-white-500"
            >
              <option value="">DD</option>
              {Array.from({ length: getDaysInMonth(month, year) }, (_, index) => index + 1).map((day) => (
                <option
                  key={day}
                  value={day}
                  className={day === "" ? "" : "text-black"}
                >
                  {day}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col">
            <label htmlFor="month" className="text-lg md:text-xl selector-label">Mes:</label>
            <select
              id="month"
              value={month}
              onChange={handleMonthChange}
              className="bg-transparent border-2 border-gray-200 rounded-full py-1 md:py-2 px-2 md:px-4 text-white text-center text-xl md:text-2xl leading-tight focus:outline-none focus:border-white-500"
            >
              <option value="">MM</option>
              {Array.from({ length: 12 }, (_, index) => index + 1).map((month) => (
                <option
                  key={month}
                  value={month}
                  className={month === "" ? "" : "text-black"}
                >
                  {month}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col">
            <label htmlFor="year" className="text-lg md:text-xl selector-label">Año:</label>
            <select
              id="year"
              value={year}
              onChange={handleYearChange}
              className="bg-transparent border-2 border-gray-200 rounded-full py-1 md:py-2 px-2 md:px-4 text-white text-center text-xl md:text-2xl leading-tight focus:outline-none focus:border-white-500"
            >
              <option value="">AAAA</option>
              {Array.from(
                { length: new Date().getFullYear() - 1940 + 1 },
                (_, index) => new Date().getFullYear() - index
              ).map((year) => (
                <option
                  key={year}
                  value={year}
                  className={year === "" ? "" : "text-black"}
                >
                  {year}
                </option>
              ))}
            </select>
          </div>

        </form>

        <div className="flex justify-center mt-4">
          <button className="btn-confirmar" onClick={handleValidAge}>
            ingresar
          </button>
        </div>
        <a className='code-copy text-white' href='https://code.com.gt' target='_blank'>powered by CODE</a>
      </div>
    </div>
  );
};

export default BirthDateForm;
