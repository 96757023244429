import firebase from "firebase/compat/app";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAe9yAkXxbpdaEqGKm3_PbBSdEwWX4o22o",
  authDomain: "finalheineken.firebaseapp.com",
  databaseURL: "https://finalheineken-default-rtdb.firebaseio.com",
  projectId: "finalheineken",
  storageBucket: "finalheineken.appspot.com",
  messagingSenderId: "271625805757",
  appId: "1:271625805757:web:c36f1c002dcf09dcd33cd4",
  measurementId: "G-Y92BV8QEZZ"
};

firebase.initializeApp(firebaseConfig);
const storage = firebase.storage();

export { storage };
