import React, { useState, useEffect } from 'react';
import config from '../config/Config';
import axios from 'axios';

const Table = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(config.API('getAllDataCustomers'));
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error al obtener los datos:', error);
      }
    };

    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, 300000); // Actualizar cada 5 minutos (300,000 ms)

    return () => clearInterval(interval); // Limpiar intervalo al desmontar el componente
  }, []);

  return (
    <div style={{ backgroundColor: "rgba(25,122,27,0.8)" }}
      className="bg-slate-950 bg-opacity-70 absolute right-10 md:right-40 p-8 top-10 md:top-10 w-96 md:w-2/5 mx-auto md:text-center center-mobile">
      <div className="container mx-auto">
        <div className="w-full md:w-5/5">
          {loading ? (
            <p className="text-gray-600 text-xl">Cargando...</p>
          ) : (
            <table className="table-auto bg-white w-full">
              <thead>
                <tr>
                  <th className="px-4 py-2 bg-green-800 text-white text-2xl md:text-3xl text-white">Cantidad</th>
                  <th className="px-4 py-2 bg-green-800 text-white text-2xl md:text-3xl text-white">Fecha</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td className="border px-4 py-2 text-gray-700 text-1xl md:text-1xl">{item.totalPerson} usuarios registrados</td>
                    <td className="border px-4 py-2 text-gray-700 text-1xl md:text-1xl">{item.createDate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default Table;
