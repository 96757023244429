// Define global config will be used on endpoints
// Avoid have the endpoint url on different components or pages
const config = {
    BASE_URL: 'https://us-central1-finalheineken.cloudfunctions.net/heineken',
    // BASE_URL: 'http://127.0.0.1:5001/finalheineken/us-central1/heineken',

    API(endpoint) {
        return `${this.BASE_URL}/${endpoint}`;
    }
}

export default config;