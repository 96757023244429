import React from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import ConfetiAnimation from './components/ConfetiAnimation';
import BirthDayForm from './components/BirthDateForm';
import DataUserForm from './components/DataUserForm';
import SetMatch from './components/SetMatch';
import IncorrectCodeScreen from "./components/IncorrectCodeScreen";
import CongratulationsScreen from "./components/CongratulationsScreen";
import ConfimationPage from './components/ConfimationPage';
import TableData from './components/TableData';
import Ranking from './components/Ranking';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <ConfetiAnimation /> {/* Mostrar ConfetiAnimation en todas las rutas */}
        <Routes>
          <Route path="/dataUser" element={<DataUserForm />} />
          <Route path="/invalidate" element={<IncorrectCodeScreen />} />
          <Route path="/validate" element={<CongratulationsScreen />} />
          <Route path="/confirmation/:code" element={<ConfimationPage />} />
          <Route path="/set-match" element={<SetMatch />} />
          <Route path="/confirmation/users/data/getAllUsersTotal" element={<TableData />} />
          <Route path="/" element={<BirthDayForm />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
